<template>
    <div class="mentor">
        <div class="top">
            <div class="t-header">
                <div class="title">
                    우리 아이들의 꿈을 응원하는<br>
                    <span>아빠미소멘토단</span>
                </div>
            </div>
            <div class="filter-wrap clear">
                <button class="write" @click="moveWrite" v-if="returnHasCreate()">글작성</button>
                <div class="search">
                    <el-select class="select center" v-model="filterInputOption">
                        <el-option :value="'su'" :label="'제목'"></el-option>
                    </el-select>
                    <div class="input">
                        <input type="text" v-model="filterInput"
                               @keypress.enter="setFilterFunc(filterInputOption, filterInput)"></input>
                        <button class="search_btn" @click="setFilterFunc(filterInputOption, filterInput)">검색</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<!--dd-->
<script>
    import {mapState, mapGetters} from "vuex";
    import EventBus from "@/utils/event-bus";
    import boardFilterUtil from "@/components/board/boardFilterUtil";

    const {filterConfig} = boardFilterUtil;

    export default {
        name: "BoardFilterMentorCommuLayout",
        inject: ['setPage', 'setData', 'setFilter'],
        props: {
            filterData: {
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                filterInput: '',
                filterInputOption: 'su',
            }
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        created() {
            this.setterFilterDataInData();
        },
        mounted() {

        },
        methods: {
            setterFilterDataInData() {
                let classnames = Object.keys(this.filterData);
                classnames.forEach(classname => {
                    let obj = this.filterData[classname];
                    let type = filterConfig[obj.key].type;
                    if (this.hasOwnProperty(classname)) {
                        // this[classname] =
                        if (type == 'query') {
                            this[classname] = obj.query;
                        } else if (type === 'between') {
                            this[classname] = [obj.start, obj.end];
                        } else if (type === 'match') {
                            this[classname] = obj.value;
                        }
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }

                    }
                })
            },
            parentSetData(key, value) {
                this[key] = value;
                this.setData(key, value);
            },
            setFilterFunc(key, value) {
                let classname = filterConfig[key].classname;
                if (!classname) {
                    return;
                }
                this[classname] = value;
                this.setData(classname, value);
                this.setFilter(key, value);
            },
            moveWrite() {
                this.$router.replace(this.$route.path);
                this.$nextTick(() => {
                    this.$router.push('mentor/write');
                })
            },
            returnHasCreate() {
                return this.UserInfo.mb_type === 1;
            },
        },
        watch: {},

    }
</script>

<style scoped>

</style>